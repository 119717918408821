import './slider.scss';
import ImagesLoaded from 'Imagesloaded';
import Swiper from 'Swiper';

class Slider {

    constructor(element, options) {

        const settings = {
            autoHeight: false, 
            autoplay: false, 
            pauseOnHover: true, 
            calculateHeight: true, 
            centeredSlides: false, 
            counter: false, 
            crossfade: false, 
            effect: 'slide', 
            equalHeights: false, 
            fractionText: '/', 
            freeMode: false, 
            freeModeSticky: false, 
            fullscreen: false, 
            fullscreenAttr: 'fullscreen', 
            indicators: false, 
            indicatorsInControls: true, 
            initAttr: 'data-slider', 
            initialSlide: 0, 
            lazyLoading: false, 
            longSwipesRatio: 0.0025, 
            loop: false, 
            loopedSlides: null, 
            mousewheel: false, 
            parallax: false, 
            prevnext: true, 
            resistanceRatio: 0.85,
            scrollbar: false, 
            shortSwipes: true, 
            speed: 500,
            slidesPerView: 1, 
            slidesPerGroup: 1,  
            preloadImages: true, 
            breakpoints: {
                1023: {
                    slidesPerView: 1, 
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 1, 
                    slidesPerGroup: 1
                }
            }, 
            resizeTimeout: 150, 
            wrapPrevnext: true, 
            onBefore: null, 
            onFullscreen: null, 
            onInit: null,
            onResize: null, 
            onSlide: null, 
            onSlidePrev: null, 
            onSlideStart: null, 
            onSlideNext: null, 
            onLazyLoaded: null,
            simulateTouch: true,
            spaceBetween: 0
        };

        this.settings = Object.assign({}, settings, options);

        this.$slider = element;
        this.$slidesList = this.$slider.querySelector('[' + this.settings.initAttr + '="slides"]');
        this.$slides = this.$slidesList.querySelectorAll('[' + this.settings.initAttr + '="slide"]');

        if(this.$slides.length > 1) {
            ImagesLoaded(this.$slides, () => {
                this.initialize();
            });
        }
    }

    initialize() {

        let autoplaySettings = false, 
            paginationType = 'bullets', 
            $controls = null, 
            $fullscreenButton = null, 
            $scrollbar = null, 
            $prevButton = null, 
            $nextButton = null,
            resizeTimer = null,  
            currentIndex = this.settings.initialSlide, 
            $window = window;

        const calculateContainerHeight = () => {
            
            let maxHeight = 0;
            this.$slidesList.style.height = 'auto';
            
            if(this.settings.equalHeights === true) {
             
                for(let i = 0; i < this.$slides.length; i++) {
                    let $slide = this.$slides[i];
                    $slide.style.height = 'auto';
                }
            }

            for(let i = 0; i < this.$slides.length; i++) {
            
                let $slide = this.$slides[i];
                if($slide.offsetHeight > maxHeight) {
                    maxHeight = $slide.offsetHeight;
                }
            }
            
            this.$slidesList.style.height = maxHeight + 'px';

            if(this.settings.equalHeights === true) {
            
                for(let i = 0; i < this.$slides.length; i++) {
                
                    let $slide = this.$slides[i];
                    $slide.style.height = maxHeight + 'px';
                }
            }
        };

        const buildSlider = () => {
            let $slidesWrapper = document.createElement('div');
            let amountSlides = this.$slides.length;
            $slidesWrapper.classList.add('swiper-wrapper');

            for(let i = 0; i < this.$slides.length; i++) {
                this.$slides[i].classList.add('swiper-slide');
                this.$slides[i].setAttribute('data-swiper-slide-index', i);
                $slidesWrapper.appendChild(this.$slides[i]);
            }

            let $slidesContainer = document.createElement('div');
            $slidesContainer.classList.add('swiper-container');
            this.$slidesList.classList.add('swiper-length-' + amountSlides);

            $slidesContainer.appendChild($slidesWrapper);

            this.$slidesList.appendChild($slidesContainer);

            return $slidesContainer;
        };

        this.$slidesContainer = buildSlider();

        if(this.settings.scrollbar === true) {
            $scrollbar = document.createElement('div');
            $scrollbar.classList.add('swiper-scrollbar');

            this.$slidesContainer.appendChild($scrollbar);
        }

        if(this.settings.counter === true) {
            paginationType = 'fraction';
        }

        if(this.settings.prevnext === true) {

            $prevButton = document.createElement('div');
            $prevButton.classList.add('swiper-button-prev');
            $prevButton.innerHTML = '<i>prev</i>';
            $prevButton.setAttribute(this.settings.initAttr, 'prev');

            $nextButton = document.createElement('div');
            $nextButton.classList.add('swiper-button-next');
            $nextButton.innerHTML = '<i>next</i>';
            $nextButton.setAttribute(this.settings.initAttr, 'next');

            if(this.settings.wrapPrevnext === true) {
                $controls = document.createElement('div');
                $controls.classList.add('swiper-controls');
                $controls.appendChild($prevButton);
                $controls.appendChild($nextButton);

                this.$slidesList.appendChild($controls);
            } else {
                this.$slidesList.appendChild($prevButton);
                this.$slidesList.appendChild($nextButton);
            }
        }

        if(this.settings.fullscreen === true) {

            $fullscreenButton = document.createElement('div');
            $fullscreenButton.classList.add('swiper-button-fullscreen');
            $fullscreenButton.innerHTML = '<i>Fullscreen</i>';
            $fullscreenButton.setAttribute(this.settings.initAttr, 'fullscreen');

            if(this.settings.wrapPrevnext === true) {

                $controls.appendChild($fullscreenButton);

                if(this.settings.prevnext === true) {
                    $controls.appendChild($prevButton);
                    $controls.appendChild($nextButton);
                }
            } else {
                this.$slidesList.appendChild($fullscreenButton);
                if(this.settings.prevnext === true) {
                    this.$slidesList.appendChild($prevButton);
                    this.$slidesList.appendChild($nextButton);
                }
            }
        }

        if(this.settings.indicators === true || this.settings.counter === true) {
            let $swiperPagination = document.createElement('div');
            $swiperPagination.classList.add('swiper-pagination');

            if(this.settings.indicatorsInControls === true && this.settings.wrapPrevnext === true) {
                $controls.appendChild($swiperPagination);
            } else {
                this.$slidesList.appendChild($swiperPagination);
            }
        }

        if(this.settings.autoplay !== false) {

            autoplaySettings = {
                delay: this.settings.autoplay, 
                disableOnInteraction: this.settings.pauseOnHover
            };
        }

        if(this.settings.calculateHeight === true) {

            $window.addEventListener('resize', () => {
                clearTimeout(resizeTimer);
                resizeTimer = $window.setTimeout(() => {
                    calculateContainerHeight();
                }, this.settings.resizeTimeout);
            });

            $window.addEventListener('orientationchange', () => {
                clearTimeout(resizeTimer);
                resizeTimer = $window.setTimeout(() => {
                    calculateContainerHeight();
                }, this.settings.resizeTimeout);
            });

            calculateContainerHeight();
        }

        this.slider = new Swiper(this.$slidesContainer, {
            autoHeight: this.settings.autoHeight, 
            autoplay: autoplaySettings, 
            autoplayDisableOnInteraction: this.settings.pauseOnHover, 
            breakpoints: this.settings.breakpoints,
            centeredSlides: this.settings.centeredSlides, 
            effect: this.settings.effect,
            spaceBetween: this.settings.spaceBetween,
            fadeEffect: {
                crossFade: this.settings.crossfade
            },
            freeMode: this.settings.freeMode, 
            freeModeSticky: this.settings.freeModeSticky, 
            initialSlide: this.settings.initialSlide, 
            lazy: this.settings.lazyLoading, 
            loop: this.settings.loop, 
            loopedSlides: this.settings.loopedSlides,
            mousewheel: this.settings.mousewheel, 
            navigation: {
                nextEl: $nextButton, 
                prevEl: $prevButton, 
            },
            normalizeSlideIndex: true, 
            observer: true, 
            pagination: {
                clickable: true, 
                el: this.$slidesList.querySelector('.swiper-pagination'),
                type: paginationType, 
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' +
                            ' ' + this.settings.fractionText + ' ' +
                            '<span class="' + totalClass + '"></span>';
                }
            },
            preloadImages: this.settings.preloadImages, 
            parallax: this.settings.parallax, 
            resistanceRatio: this.settings.resistanceRatio, 
            roundLengths: true, 
            scrollbar: {
                el: $scrollbar,
                draggable: true
            },
            simulateTouch: this.settings.simulateTouch, 
            shortSwipes: this.settings.shortSwipes, 
            longSwipesRatio: this.settings.longSwipesRatio, 
            slidesPerView: this.settings.slidesPerView, 
            slidesPerGroup: this.settings.slidesPerGroup, 
            speed: this.settings.speed,  
            on: {
                init: () => {
                    
                    if(this.settings.calculateHeight === true) {
                        calculateContainerHeight();
                    }
                    
                    if(typeof this.settings.onInit === 'function') {
                        this.settings.onInit(this.$slider, this.$slider.querySelector('.swiper-slide-active'), this.settings.initialSlide);
                    }

                    this.$slidesList.classList.add('swiper-items');

                    if(this.settings.fullscreen === true) {

                        $fullscreenButton.addEventListener('click', () => {
                            this.settings.onFullscreen(currentIndex);
                        });
                    }
                },
                slideChangeTransitionEnd: () => {
                    
                    let $current = this.$slider.querySelector('.swiper-slide-active');
                    
                    currentIndex = $current.getAttribute('data-swiper-slide-index');
                    
                    if(typeof this.settings.onSlide === 'function') {
                        this.settings.onSlide(this.$slider, $current, currentIndex);
                    }
                }, 
                lazyImageReady: () => {

                    if(typeof this.settings.onLazyLoaded === 'function') {
                        this.settings.onLazyLoaded(this.$slider, this.$slider.querySelector('.swiper-slide-active'));
                    }
                }, 
                slideChangeTransitionStart: (swiper) => {
                    
                    if(typeof this.settings.onSlideStart === 'function') {
                        this.settings.onSlideStart(this.$slider, swiper);
                    }
                }, 
                slidePrevTransitionStart: () => {
                    
                    if(typeof this.settings.onSlidePrev === 'function') {
                        this.settings.onSlidePrev();
                    }
                }, 
                slideNextTransitionStart: () => {

                    if(typeof this.settings.onSlideNext === 'function') {
                        this.settings.onSlideNext();
                    }
                },
                resize: () => {
                    
                    if(typeof this.settings.onResize === 'function') {
                        this.settings.onResize(this.$slider);
                    }
                }
            }
        });
    }

    sliderPrev() {

        this.slider.slidePrev();
    }

    sliderNext() {

        this.slider.slideNext();
    }

    sliderTo(i) {

        this.slider.slideTo(i);
    }
    // @TODO: add destroySlider, refresh, removeSlides, addSlide, slidePrev, slideNext, slideTo, update functions
}

export { Slider };
