import './logo-slider.scss';
import { Slider } from '../../components/slider/slider';

class LogoSlider {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-logoslider'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.slider = null;

        // Elements
        this.logoSlider = element;
    }

    initialize () {
        console.log('init-slider');
        this.initSlider();
    }

    initSlider () {
        this.slider = new Slider(this.logoSlider, {
            initAttr: 'data-logoslider',
            freeMode: true,
            scrollbar: true,
            speed: 500,
            slidesPerView: 2,
            slidesPerGroup: 2,
            mousewheel: {
                forceToAxis: true,
                invert: true
            },
            breakpoints: {
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            }
        });
    }
}

export { LogoSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    console.log(context);
    if (context) {
        const $$logoSlider = context.querySelectorAll('[data-logoslider="root"]');
        for (let i = 0; i < $$logoSlider.length; i++) {

            const $logoSlider = $$logoSlider[i];

            const $logoSliderAPI = new LogoSlider($logoSlider);
            $logoSliderAPI.initialize();
        }
    }
});
